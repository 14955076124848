import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import { ReactComponent as Circle } from "../images/circle.svg"
import { ReactComponent as Arrow } from "../images/arrow.svg"
import { ReactComponent as RadnoVrijeme } from "../images/radno-vrijeme.svg"
import { ReactComponent as BonApetit } from "../images/bon-apetit.svg"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Konoba Fakin" />
      <div className="pocetna-container row">
        <div className="col">
          <Link to="/galerija" className="box gallery">
            <Img
              fluid={{
                ...data.galerija.childImageSharp.fluid,
                sizes:
                  "(min-width: 769px) 450px,(min-width: 451px)  768px, 450px",
              }}
            />
            <h2>
              Gal <br />
              lery
            </h2>
            <Arrow />
          </Link>

          <div className="box hours">
            <Img
              fluid={{
                ...data.rezervacija.childImageSharp.fluid,
                sizes:
                  "(min-width: 769px) 450px,(min-width: 451px)  768px, 450px",
              }}
            />
            <RadnoVrijeme />
            <div className="button-container">
              <a href="mailto:info@konobafakin.com">
                <button>
                  <h2>Reserve</h2>
                  <p>your table</p>
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="box menu">
          <Img
            fluid={{
              ...data.menu.childImageSharp.fluid,
              sizes:
                "(min-width: 769px) 450px,(min-width: 451px)  768px, 450px",
            }}
          />
          <div className="menu-container">
            <div className="title-contaner">
              <Circle />
              <h2>Menu</h2>
              <Circle />
            </div>
            <div>
              <Link to="/menu">
                <div className="title">
                  <div className="outline">Hrvatski</div> Hrvatski
                </div>{" "}
                <Arrow />
              </Link>
              <Link to="/menu-en">
                <div className="title">
                  <div className="outline">English</div>
                  English
                </div>
                <Arrow />
              </Link>
              <Link to="/menu-it">
                <div className="title">
                  <div className="outline">Italiano</div>
                  Italiano
                </div>
                <Arrow />
              </Link>
              <Link to="/menu-de">
                <div className="title">
                  <div className="outline">Deutsch</div>
                  Deutsch
                </div>
                <Arrow />
              </Link>
              <Link to="/menu-fr">
                <div className="title">
                  <div className="outline">Francois</div>
                  Francois
                </div>
                <Arrow />
              </Link>
            </div>
            <BonApetit />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    menu: file(relativePath: { eq: "menu.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    galerija: file(relativePath: { eq: "gallery.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rezervacija: file(relativePath: { eq: "rezervacija.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
